 import type { Article } from '@pigello/pigello-matrix';

import { ArticleConfig } from "@pigello/pigello-matrix"



import { useGetInstance, useGetList } from '@/requests/hooks';
import type { useGetGenericInstanceProps, useGetGenericListProps, } from '@/requests/types';


export const useGetArticle = (
  options: useGetGenericInstanceProps<Article>
) => {
  return useGetInstance<Article>({
    ...options,
    config: ArticleConfig,
    modelName: 'article'
  });
};

export const useGetArticleList = (
  options: useGetGenericListProps<Article>
) => {
  return useGetList<Article>({
    ...options,
    config: ArticleConfig,
    modelName: 'article'
  });
};
