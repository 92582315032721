import { CardContentRow, CardContentRowTitle } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { cn } from '@/lib/utils';
import { motion } from 'framer-motion';

type CardSectionProps = {
  isLoading?: boolean;
  className?: string;
  textClassName?: string;
  contentClassName?: string;
  items: {
    title?: string | React.ReactNode;
    content?: React.ReactNode;
    hidden?: boolean;
  }[];
  border?: boolean;
};

const randomWidthTitle: Record<number, string> = {
  7: 'w-8',
  1: 'w-20',
  10: 'w-12',
  5: 'w-6',
  13: 'w-8',
  6: 'w-14',
  4: 'w-20',
  0: 'w-16',
  12: 'w-24',
  15: 'w-20',
  3: 'w-28',
  11: 'w-8',
  8: 'w-12',
  2: 'w-10',
  9: 'w-6',
};
const randomWidthContent: Record<number, string> = {
  7: 'w-6',
  1: 'w-8',
  10: 'w-10',
  5: 'w-12',
  13: 'w-14',
  6: 'w-20',
  4: 'w-24',
  0: 'w-28',
  12: 'w-10',
  15: 'w-8',
  3: 'w-6',
  11: 'w-12',
  8: 'w-14',
  2: 'w-20',
  9: 'w-24',
};

export function CardSection({
  items,
  isLoading,
  className,
  border,
  textClassName,
  contentClassName,
}: CardSectionProps) {
  if (isLoading && items.length === 0) {
    return Array.from({ length: 3 }).map((_, i) => (
      <CardContentRow
        key={crypto.randomUUID()}
        className={cn('items-center last:rounded-b-lg')}
      >
        <CardContentRowTitle className='whitespace-pre-wrap'>
          <Skeleton className={`h-2.5 ${randomWidthTitle[i]}`} />
        </CardContentRowTitle>
        <div className='ml-2 whitespace-pre-wrap text-end text-sm'>
          <Skeleton className={`h-2.5 ${randomWidthContent[i]}`} />
        </div>
      </CardContentRow>
    ));
  }

  return items
    .filter((i) => !i.hidden)
    .map(({ title, content }, i) => (
      <CardContentRow
        className={cn(
          'last:border-none',
          border && 'border-b border-border/40',
          className
        )}
        key={typeof title === 'string' ? title : i}
      >
        <CardContentRowTitle
          className={cn(
            'flex items-center whitespace-pre-wrap text-foreground/80',
            textClassName
          )}
        >
          <motion.span initial={{ opacity: 0.5 }} animate={{ opacity: 1 }}>
            {title}
          </motion.span>
        </CardContentRowTitle>
        <div
          className={cn(
            'flex items-center whitespace-pre-wrap text-end text-sm font-medium',
            contentClassName
          )}
        >
          {isLoading ? (
            <Skeleton className={`my-1 h-3 ${randomWidthContent[i]}`} />
          ) : (
            <motion.span initial={{ opacity: 0.5 }} animate={{ opacity: 1 }}>
              {content ?? '-'}
            </motion.span>
          )}
        </div>
      </CardContentRow>
    ));
}
