'use client';
import type { SortingState } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  type ColumnDef,
  type Row,
} from '@tanstack/react-table';

import { useReactTable } from '@tanstack/react-table';

import { Skeleton } from '@/components/ui/skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { cn } from '@/lib/utils';
import { useEffect, useMemo, useState } from 'react';

export default function InvoiceRowTable<TData, TValue>({
  data,
  isLoading,
  columns,
  onRowClick,
  searchValue,
  noStickyTableHeader,
}: {
  data: TData[] | undefined;
  columns: ColumnDef<TData, TValue>[];
  isLoading: boolean;
  onRowClick?: (id: string) => void;
  searchValue?: string;
  noStickyTableHeader?: boolean;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [search, setSearch] = useState('');

  const memoizedCols = useMemo(() => columns, [columns]);
  const memoizedData = useMemo(() => data ?? [], [data]);

  const table = useReactTable({
    data: memoizedData,
    columns: memoizedCols,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    enableSorting: true,
    enableMultiSort: true,
    enableGlobalFilter: true,
    onGlobalFilterChange: setSearch,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: 'includesString',
    state: {
      sorting,
      globalFilter: search,
    },
  });

  useEffect(() => {
    if (!searchValue) {
      setSearch('');
      return;
    }
    setSearch(searchValue);
  }, [searchValue]);

  const handleClicked = (
    { target }: { target: Partial<HTMLTableRowElement> },
    row: Row<TData>
  ) => {
    if (!onRowClick) return;
    const contained = target.childNodes?.[0] as HTMLElement;
    const hasCheckBox = contained && contained?.role === 'checkbox';
    const hasOnClickListener = !!target?.onclick;

    if (!target?.tagName) return;

    if (
      ['div', 'td', 'span'].includes(target.tagName.toLowerCase()) &&
      !hasCheckBox &&
      !hasOnClickListener
    ) {
      const original = row.original;
      onRowClick(
        original && 'id' in (original as unknown as { id: string })
          ? ((original as unknown as { id: string }).id as string)
          : row.id
      );
    }
  };

  return (
    <div className='relative flex-1 overflow-x-auto'>
      <Table>
        <TableHeader
          className={cn(
            'sticky top-0 shadow-sm',
            noStickyTableHeader && 'static'
          )}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead
                  className='h-auto bg-background px-2'
                  key={header.id}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody className='overflow-y-auto'>
          {isLoading &&
            Array(5)
              .fill(0)
              .map(() => (
                <TableRow
                  key={crypto.randomUUID()}
                  className='hover:bg-transparent'
                >
                  {Array(columns.length - 1)
                    .fill(0)
                    .map(() => (
                      <TableCell
                        key={crypto.randomUUID()}
                        className={cn('transition-all ', 'py-0.5')}
                      >
                        <Skeleton className='h-6 w-full min-w-[20px] bg-foreground/20' />
                      </TableCell>
                    ))}
                </TableRow>
              ))}
          {table.getRowModel().rows?.length > 0 &&
            !isLoading &&
            table.getRowModel().rows.map((row) => (
              <TableRow
                className={cn({
                  'cursor-pointer': !!onRowClick,
                })}
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
                onClick={(event) => handleClicked(event, row)}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    className={cn('h-auto px-2 py-1 transition-all ')}
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
        <TableFooter>
          {table.getFooterGroups().map((footerGroup) => (
            <TableRow className='border-b-0' key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <TableHead
                  className='h-auto bg-background px-2'
                  key={header.id}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableFooter>
      </Table>
    </div>
  );
}
