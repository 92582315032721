import { type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@/lib/utils';
import { cva } from 'class-variance-authority';

export const badgeBorderColorMap: Record<keyof typeof badgeVariants, string> = {
  default: 'border-primary-foreground',
  gray: 'border-[#FAFAFA]',
  'light-gray': 'border-[#999999]',
  green: 'border-[#306339]',
  yellow: 'border-[#7E5120]',
  blue: 'border-[#36309D]',
  purple: 'border-[#6326A2]',
  orange: 'border-[#B44C24]',
  cyan: 'border-[#2D5D73]',
  destructive: 'border-destructive',
};

export type IBadgeVariants =
  | 'default'
  | 'gray'
  | 'light-gray'
  | 'green'
  | 'yellow'
  | 'blue'
  | 'purple'
  | 'orange'
  | 'cyan'
  | 'destructive'
  | 'outline'
  | null
  | undefined;
export type BadgeVariant = VariantProps<typeof badgeVariants>;

const badgeVariants = cva(
  'inline-flex items-center whitespace-nowrap rounded-sm border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default: 'border-transparent bg-primary text-primary-foreground',
        gray: 'border-transparent bg-[#7D7D7D] text-[#FAFAFA] dark:border-[#8F8F8F] dark:bg-transparent dark:text-[#8B8B8B]',
        'light-gray':
          'border-transparent bg-[#F7F7F7] text-[#999999] dark:border-[#C8C8C8] dark:bg-transparent dark:text-[#C8C8C8]',
        green:
          'border-transparent bg-[#DCFCE7] text-[#306339] dark:border-[#86F4AC] dark:bg-transparent dark:text-[#86F4AC]',
        yellow:
          'border-transparent bg-[#FEF9C3] text-[#7E5120] dark:border-[#FFF7A5] dark:bg-transparent dark:text-[#FFF7A5]',
        blue: 'border-transparent bg-[#E1E7FD] text-[#36309D] dark:border-[#5047E7] dark:bg-transparent dark:text-[#5047E7]',
        purple:
          'border-transparent bg-[#F1E8FD] text-[#6326A2] dark:border-[#955BD1] dark:bg-transparent dark:text-[#955BD1]',
        orange:
          'border-transparent bg-[#FCEED8] text-[#B44C24] dark:border-[#FFCC7C] dark:bg-transparent dark:text-[#FFCC7C]',
        cyan: 'border-transparent bg-[#D7F9FD] text-[#2D5D73] dark:border-[#61D3E1] dark:bg-transparent dark:text-[#61D3E1]',
        destructive:
          'border-transparent bg-[#F9E3E2] text-[#D41212] dark:border-[#E24747] dark:bg-transparent dark:text-[#E24747]',
        outline: 'text-foreground',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };
