 import type { InvoiceRow } from '@pigello/pigello-matrix';

import { InvoiceRowConfig } from "@pigello/pigello-matrix"



import { useGetInstance, useGetList } from '@/requests/hooks';
import type { useGetGenericInstanceProps, useGetGenericListProps, } from '@/requests/types';


export const useGetInvoiceRow = (
  options: useGetGenericInstanceProps<InvoiceRow>
) => {
  return useGetInstance<InvoiceRow>({
    ...options,
    config: InvoiceRowConfig,
    modelName: 'invoicerow'
  });
};

export const useGetInvoiceRowList = (
  options: useGetGenericListProps<InvoiceRow>
) => {
  return useGetList<InvoiceRow>({
    ...options,
    config: InvoiceRowConfig,
    modelName: 'invoicerow'
  });
};
