import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import { InformationCircleIcon } from '@heroicons/react/16/solid';

export function DescriptionTooltip({
  description,
  className,
}: {
  description?: string;
  className?: string;
}) {
  return (
    <Tooltip>
      <TooltipTrigger
        asChild
        className='ml-2'
        onClick={(e) => e.preventDefault()}
      >
        <InformationCircleIcon className='size-[14px] shrink-0' />
      </TooltipTrigger>
      <TooltipContent className={cn('max-w-sm', className)}>
        <div className='text-sm text-muted-foreground'>{description}</div>
      </TooltipContent>
    </Tooltip>
  );
}
